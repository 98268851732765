<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <div class="d-flex justify-start">
            <v-btn color="primary" v-if="!api.isLoading" @click="()=>{
                this.$router.go(-1);
            }">
                <v-icon >
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-data-table
        class="elevation-1 mt-5"
        v-if="data!=null && !api.isLoading"
        :items="data"
        :search="searchResult"
        :headers="headers">
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" color="blue lighten-4 py-3">
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold">
                                    Receipts & Invoices
                                </v-toolbar-title>
                            </v-row>
                            <v-divider>
                            </v-divider>
                            <v-row class="mt-3">
                                <v-text-field
                                dense
                                outlined
                                clearable
                                v-model="searchResult"
                                label="search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <AError :api="api"/>
            </template>
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template> 
            <!-- <template v-slot:item.name="{item}">
                <a @click="redirectToCompany(data[data.indexOf(item)].companies_id)" class="text-decoration-none">
                {{ item.name }}
                </a>
            </template> -->
            <!-- <template v-slot:item.fname="{item}">
                {{ item.fname }} {{ item.lname }}
            </template> -->
            <template v-slot:item.created_at="{item}">
                {{ item.created_at.split("T")[0] }}
                {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}}

            </template>
            <!-- <template v-slot:item.delete="{item}">
                <v-btn color="red" plain @click="validateInput(item.attachment_id)">Delete</v-btn>
            </template> -->
            <template v-slot:item.attachment_name="{item}">
                <a :href="item.attachment_url" target="_blank" download>
                    {{ item.attachment_name }}
                </a>
                <!-- <a @click="redirectDetail(item.form_id)">
                    {{ item.attachment_name }}
                </a> -->
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AError from '../../components/common/AError.vue';
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AError,
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        attachment:false,
        searchResult:null,
        isPending:false,
        data:null,
        headers:[
            {
                text:'#',
                value:"Index",
                align:"start",
                sortable:false,
            },
            {
                text:'Attachment',
                value:'attachment_name',
            },
            {
                text:'Type',
                value:'attachment_type',
            },
            {
                text:'Uploader',
                value:'user_name'
            },
            {
                text:'Date',
                value:'created_at',
            },
            // {
            //     text:'Action',
            //     value:'delete',
            // }
    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getAttachmentCompany"){
                this.data = resp.data;
            }
            if(resp.class=="deleteAttachment"){
                location.reload();
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchAttachment(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/attachment/company/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetch(){
            let fetchAttachmentApi = this.fetchAttachment();
            this.$api.fetch(fetchAttachmentApi);
        },
        redirectToCompany(companyId){
            this.$store.commit("updateCompany",companyId);
            this.$router.push({name:'PageCompaniesDetail',params:{'id':companyId}});
        },
        validateInput(attachmentId){
            this.isPending = true;
            this.attachment = attachmentId;
        },
        submit(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/attachment/"+this.attachment;
            this.$api.fetch(tempApi);
        },
        cancelSubmit(){
            this.attachment = null;
            this.isPending = false;
            return 1;
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
            // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
        },
        // redirectDetail(formId){
        //     this.$store.commit("updateForm",formId);
        //     this.$router.push({name:'PageReceiptDetail'});
        // }

    }
}
</script>