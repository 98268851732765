var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-start"},[(!_vm.api.isLoading)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
            this$1.$router.go(-1);
        }}},[_c('v-icon',[_vm._v(" mdi-arrow-left-bold ")])],1):_vm._e()],1),(_vm.data!=null && !_vm.api.isLoading)?_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"items":_vm.data,"search":_vm.searchResult,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"width":"auto","height":"auto","color":"blue lighten-4 py-3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(" Receipts & Invoices ")])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-3"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"search"},model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}})],1)],1)],1)],1),_c('AError',{attrs:{"api":_vm.api}})]},proxy:true},{key:"item.Index",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.data.indexOf(item)+1)+" ")]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split("T")[0])+" "+_vm._s(_vm.convertTimeZone(item.created_at).split(" ")[3])+" "+_vm._s(_vm.convertTimeZone(item.created_at).split(" ")[4])+" ")]}},{key:"item.attachment_name",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":item.attachment_url,"target":"_blank","download":""}},[_vm._v(" "+_vm._s(item.attachment_name)+" ")])]}}],null,false,1656925833)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }